import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-security-privacy',
    templateUrl: './security-privacy.component.html',
    styleUrls: ['./security-privacy.component.scss'],
    standalone: false
})
export class SecurityPrivacyComponent implements OnInit {
  constructor(
    protected dialogRef: MatDialogRef<SecurityPrivacyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public isModal: boolean,
  ) {}

  ngOnInit(): void {}
}
