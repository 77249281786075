import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PanelWidth } from '@app/shared/enums';
import { PanelService } from '@core/services';
import { SecurityPrivacyComponent } from '@core/ui';

@Component({
  selector: 'app-security-link',
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './security-link.component.html',
  styleUrls: ['./security-link.component.scss'],
  standalone: true,
})
export class SecurityLinkComponent {
  constructor(private panelService: PanelService) {}

  public openSecurity(): void {
    this.panelService.open<boolean, boolean>(SecurityPrivacyComponent, true, {
      maxWidth: PanelWidth.Large,
    });
  }
}
