import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationTypes } from '@app/shared/enums';
import {
  AppStoreState,
  AuthenticationActions,
  AuthenticationSelectors,
  NotificationActions,
} from '@app/store';
import { AuthService } from '@core/services';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MFATypes, SignInResponse } from '../auth.models';

@Component({
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss'],
    standalone: false
})
export class VerifyComponent implements OnDestroy, OnInit {
  public verifySignInForm: UntypedFormGroup;
  public verifySignInLoading$ = new Observable<boolean>();

  private destroyed$ = new Subject<boolean>();
  private mfaType: MFATypes;
  private signIn$ = new Observable<SignInResponse>();

  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private store$: Store<AppStoreState.State>,
  ) {
    this.signIn$ = this.store$.select(AuthenticationSelectors.selectSignIn);

    this.verifySignInLoading$ = this.store$.select(
      AuthenticationSelectors.selectConfirmSignInLoading,
    );

    this.actions$
      .pipe(
        ofType(
          AuthenticationActions.confirmSignInFailure,
          AuthenticationActions.confirmCustomSignInFailure,
          AuthenticationActions.verifyAttributeFailure,
        ),
        takeUntil(this.destroyed$),
        tap(({ error }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: error.message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(
          AuthenticationActions.confirmSignInSuccess,
          AuthenticationActions.confirmCustomSignInSuccess,
          AuthenticationActions.verifyAttributeSuccess,
          AuthenticationActions.setMFASelectionSuccess,
        ),
        takeUntil(this.destroyed$),
        tap(() => {
          this.router.navigateByUrl('/');
        }),
      )
      .subscribe();

    this.signIn$
      .pipe(
        takeUntil(this.destroyed$),
        tap((e) => {
          if (!e) {
            this.goBack();
          }

          this.mfaType = e?.results.challengeName;
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.verifySignInForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public goBack(): void {
    this.router.navigate([environment.appRoutes.signIn]);
  }

  public submit(): void {
    if (this.verifySignInForm.invalid) {
      return;
    }
    const code: string = this.verifySignInForm.get('code').value;
    const action = this.auth.isSignedIn()
      ? AuthenticationActions.verifyAttribute({
          payload: { attributeName: 'phone_number', confirmationCode: code },
        })
      : this.mfaType === MFATypes.Custom
        ? AuthenticationActions.confirmCustomSignIn({
            payload: code,
          })
        : AuthenticationActions.confirmSignIn({
            payload: { code, mfaType: this.mfaType },
          });
    this.store$.dispatch(NotificationActions.reset());
    this.store$.dispatch(action);
  }
}
