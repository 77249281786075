import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationTypes } from '@app/shared/enums';
import {
  AccountActions,
  AccountSelectors,
  AppStoreState,
  AuthenticationActions,
  AuthenticationSelectors,
  NotificationActions,
} from '@app/store';
import { fieldRequiredIfValidator } from '@core/helpers';
import { CognitoAccount, CognitoResponse } from '@core/services';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MFATypes, SignInResponse } from '../auth.models';

@Component({
    templateUrl: './set-mfa.component.html',
    styleUrls: ['./set-mfa.component.scss'],
    standalone: false
})
export class SetMfaComponent implements OnDestroy, OnInit {
  public qrcode: string = null;
  public setMFASelectionForm: UntypedFormGroup;
  public setMFASelectionLoading$ = new Observable<boolean>();

  private associateSoftwareToken$: Observable<CognitoResponse>;
  private destroyed$ = new Subject<boolean>();
  private secretCode: string;
  private signIn$ = new Observable<SignInResponse>();
  private user: CognitoAccount;
  private user$: Observable<CognitoAccount>;
  private verifySoftwareToken$: Observable<CognitoUserSession>;

  constructor(
    private actions$: Actions,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private store$: Store<AppStoreState.State>,
  ) {
    this.associateSoftwareToken$ = this.store$.select(
      AuthenticationSelectors.selectAssociateSoftwareToken,
    );
    this.verifySoftwareToken$ = this.store$.select(
      AuthenticationSelectors.selectVerifySoftwareToken,
    );
    this.signIn$ = this.store$.select(AuthenticationSelectors.selectSignIn);
    this.user$ = this.store$.select(AccountSelectors.selectUserDetails);
    this.setMFASelectionLoading$ = this.store$.select(
      AuthenticationSelectors.selectSetMFASelectionLoading,
    );

    this.actions$
      .pipe(
        ofType(
          AuthenticationActions.associateSoftwareTokenFailure,
          AuthenticationActions.verifySoftwareTokenFailure,
          AuthenticationActions.setMFASelectionFailure,
          AuthenticationActions.updateUserAttributesFailure,
        ),
        takeUntil(this.destroyed$),
        tap(({ error }) => {
          console.error(error);
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: error.message,
            }),
          );
          if (error.code === 'NotAuthorizedException') {
            this.goBack();
          }
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AuthenticationActions.setMFASelectionSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          if (this.mfaSelection() === MFATypes.SMS) {
            this.store$.dispatch(
              AuthenticationActions.getInputVerificationCode({
                payload: 'phone_number',
              }),
            );
            this.router.navigateByUrl('/auth/verify');
          }
          if (this.mfaSelection() === MFATypes.Token) {
            this.store$.dispatch(
              NotificationActions.add({
                notificationType: NotificationTypes.SUCCESS,
                notificationText:
                  'Google Authentication setup has completed successfully',
              }),
            );
            this.router.navigateByUrl('/');
          }
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AuthenticationActions.updateUserAttributesSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          // only executes for SMS option
          const payload = {
            smsSettings: { PreferredMfa: true, Enabled: true },
            tokenSettings: null,
          };
          this.store$.dispatch(
            AuthenticationActions.setMFASelection({ payload }),
          );
        }),
      )
      .subscribe();

    this.signIn$
      .pipe(
        takeUntil(this.destroyed$),
        tap((e) => {
          if (!e) {
            this.goBack();
          }
        }),
      )
      .subscribe();

    this.user$
      .pipe(
        takeUntil(this.destroyed$),
        tap((user) => {
          if (user) {
            this.user = user;
          }
        }),
      )
      .subscribe();
  }

  public get MFATypes() {
    return MFATypes;
  }

  ngOnInit(): void {
    this.store$.dispatch(AccountActions.getUserDetails());
    this.setMFASelectionForm = this.formBuilder.group({
      mfaSelection: ['', Validators.required],
      countryCode: ['+1'],
      phoneNumber: [
        '',
        [
          fieldRequiredIfValidator('mfaSelection', MFATypes.SMS),
          Validators.minLength(10),
        ],
      ],
      code: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public goBack(): void {
    this.router.navigate([environment.appRoutes.signIn]);
  }

  public totpSelected(event: any): void {
    this.generateQRCode();
  }

  public mfaSelected(): boolean {
    return (
      (this.setMFASelectionForm.get('mfaSelection').valid &&
        this.mfaSelection() === MFATypes.SMS &&
        this.setMFASelectionForm.get('phoneNumber').value.length &&
        this.setMFASelectionForm.get('phoneNumber').valid) ||
      (this.mfaSelection() === MFATypes.Token &&
        this.setMFASelectionForm.get('code').valid)
    );
  }

  public mfaSelection(): string {
    return this.setMFASelectionForm.get('mfaSelection').value;
  }

  public submitMFASelectionForm(): void {
    if (this.mfaSelection() === MFATypes.SMS) {
      const phoneNumber = `${
        this.setMFASelectionForm.get('countryCode').value
      }${this.setMFASelectionForm.get('phoneNumber').value}`;
      const payload = [{ Name: 'phone_number', Value: phoneNumber }];
      this.store$.dispatch(NotificationActions.reset());
      this.store$.dispatch(
        AuthenticationActions.updateUserAttributes({ payload }),
      );
    } else {
      const code: string = this.setMFASelectionForm.get('code').value;
      const verifyPayload = {
        AccessToken: '',
        UserCode: code,
        FriendlyDeviceName: '',
      };
      this.store$.dispatch(
        AuthenticationActions.verifySoftwareToken({ payload: verifyPayload }),
      );
      this.verifySoftwareToken$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          if (response) {
            const payload = {
              smsSettings: null,
              tokenSettings: { PreferredMfa: true, Enabled: true },
            };
            this.store$.dispatch(
              AuthenticationActions.setMFASelection({ payload }),
            );
          }
        });
    }
  }

  private generateQRCode() {
    const issuer = 'FPAR';
    this.store$.dispatch(AuthenticationActions.associateSoftwareToken());
    this.associateSoftwareToken$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((secretCode) => {
        if (secretCode) {
          this.secretCode = secretCode.results;
          this.qrcode =
            'otpauth://totp/AWSCognito:' +
            this.user.username +
            '?secret=' +
            this.secretCode +
            '&issuer=' +
            issuer;
        }
      });
  }
}
