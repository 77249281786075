import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PanelWidth } from '@app/shared/enums';
import { AppStoreState, MenuSelectors } from '@app/store';
import { AppMenuList } from '@core/models';
import { PanelService } from '@core/services';
import { SecurityPrivacyComponent } from '@core/ui';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss'],
    standalone: false
})
export class AppMenuComponent implements OnDestroy, OnInit {
  @Input() menu: AppMenuList[];
  @Input() appTitle = '';
  @Input() railMode = false;

  public activeMenu$: Observable<string>;
  public today: Date = new Date();
  private destroyed$ = new Subject<boolean>();

  constructor(
    private panelService: PanelService,
    private router: Router,
    private store$: Store<AppStoreState.State>,
  ) {
    this.activeMenu$ = this.store$.select(MenuSelectors.selectActiveMenu);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public navigateToRoute(route: string): void {
    this.router.navigate([route]);
  }

  public openSecurityPrivacy($event: Event): void {
    $event.preventDefault();
    this.panelService.open<boolean, boolean>(SecurityPrivacyComponent, true, {
      maxWidth: PanelWidth.Large,
    });
  }
}
