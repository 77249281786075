import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorStrings, NotificationTypes, PanelWidth } from '@app/shared/enums';
import { InactiveUserDialogComponent } from '@app/shared/ui/dialogs';
import {
  AppStoreState,
  AuthenticationActions,
  AuthenticationSelectors,
  NotificationActions,
  UserAppActions,
  UserAppSelectors,
} from '@app/store';
import {
  AuthService,
  CognitoAuthErrorTypes,
  PanelService,
} from '@core/services';
import { SecurityPrivacyComponent } from '@core/ui';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment as env } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    templateUrl: './signin.component.html',
    styleUrls: ['../styles/auth.scss', './signin.component.scss'],
    standalone: false
})
export class SignInComponent implements OnDestroy, OnInit {
  public appVersion = '';
  public securityNoticeShown = false;
  public signInForm: UntypedFormGroup;
  public signInLoading$ = new Observable<boolean>();
  public showPasswordPlainText = false;

  private destroyed$ = new Subject<boolean>();
  private securityNoticeShown$ = new Observable<boolean>();

  constructor(
    private auth: AuthService,
    private panelService: PanelService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private store$: Store<AppStoreState.State>,
    private actions$: Actions,
  ) {
    this.appVersion = env.version;
    // If already signed in, sign out first
    if (this.auth.isSignedIn()) {
      this.store$.dispatch(AuthenticationActions.signOut());
    }

    this.signInLoading$ = this.store$.select(
      AuthenticationSelectors.selectSignInLoading,
    );

    this.securityNoticeShown$ = this.store$.select(
      UserAppSelectors.selectSecurityNoticeShown,
    );

    this.actions$
      .pipe(
        ofType(AuthenticationActions.signInFailure),
        takeUntil(this.destroyed$),
        tap(({ error, message }) => {
          if (error.name === CognitoAuthErrorTypes.PASSWORD_RESET_REQUIRED) {
            this.router.navigateByUrl('/auth/reset-password');
            return;
          }
          if (error.message === 'User is disabled.') {
            this.openInactiveUserPanel();

            return;
          }

          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: ErrorStrings.GENERIC_SIGNIN_ERROR_MESSAGE,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AuthenticationActions.signInSuccess),
        takeUntil(this.destroyed$),
        tap(({ response }) => {
          const routes = {
            newUser: '/auth/new-user',
            enterMFACode: '/auth/verify',
            setupMFA: '/auth/security',
          };

          this.router.navigateByUrl(routes[response.next]);
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this.signInForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.securityNoticeShown$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((securityNoticeShown) => {
        const fromSession: boolean =
          JSON.parse(sessionStorage.getItem('securityNoticeShown')) ?? false;
        if (fromSession) {
          this.securityNoticeShown = true;
          return;
        }

        this.securityNoticeShown = securityNoticeShown;

        if (!securityNoticeShown) {
          this.panelService
            .open<boolean, boolean>(SecurityPrivacyComponent, true, {
              maxWidth: PanelWidth.Large,
            })
            .afterClosed()
            .subscribe(() => {
              this.store$.dispatch(UserAppActions.setSecurityNoticeShown());
            });
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public signIn(): void {
    if (this.signInForm.invalid) {
      return;
    }

    const payload = {
      ...this.signInForm.value,
      username: this.signInForm.get('username').value.toLowerCase().trim(),
      password: this.signInForm.get('password').value.trim(),
    };

    this.store$.dispatch(NotificationActions.reset());
    this.store$.dispatch(AuthenticationActions.signIn({ payload }));
  }

  public openInactiveUserPanel(): void {
    this.panelService
      .open<boolean>(
        InactiveUserDialogComponent,
        {},
        {
          maxWidth: PanelWidth.Medium,
        },
      )
      .afterClosed()
      .subscribe();
  }
}
