import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { passwordValidator } from '@app/shared/directives';
import { ErrorStrings, NotificationTypes } from '@app/shared/enums';
import { checkPasswordCriteria } from '@app/shared/utilities';
import {
  AppStoreState,
  AuthenticationActions,
  AuthenticationSelectors,
  NotificationActions,
} from '@app/store';
import { StoreError } from '@core/models';
import { CognitoAuthErrorTypes } from '@core/services';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: false
})
export class ResetPasswordComponent implements OnDestroy, OnInit {
  public bypassEmailAddress = false;
  public codeSent = false;
  public emailAddress = '';
  public resetPasswordForm: UntypedFormGroup;
  public resetPasswordLoading$ = new Observable<boolean>();
  public sendPasswordResetCodeForm: UntypedFormGroup;
  public sendPasswordResetCodeLoading$ = new Observable<boolean>();

  private destroyed$ = new Subject<boolean>();
  private signInError$ = new Observable<StoreError>();

  constructor(
    private actions$: Actions,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private store$: Store<AppStoreState.State>,
  ) {
    this.sendPasswordResetCodeLoading$ = this.store$.select(
      AuthenticationSelectors.selectSendPasswordResetCodeLoading,
    );

    this.resetPasswordLoading$ = this.store$.select(
      AuthenticationSelectors.selectResetPasswordLoading,
    );

    this.signInError$ = this.store$.select(
      AuthenticationSelectors.selectSignInError,
    );

    this.actions$
      .pipe(
        ofType(AuthenticationActions.sendPasswordResetCodeFailure),
        takeUntil(this.destroyed$),
        tap(({ error, message }) => {
          if (error.name === CognitoAuthErrorTypes.USER_NOT_FOUND) {
            this.store$.dispatch(
              NotificationActions.add({
                notificationType: NotificationTypes.SUCCESS,
                notificationText: ErrorStrings.GENERIC_RESET_PASSWORD_MESSAGE,
              }),
            );
            this.codeSent = true;
            return;
          }
          console.error(error);
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AuthenticationActions.resetPasswordFailure),
        takeUntil(this.destroyed$),
        tap((error) => {
          console.error(error);
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: error.message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AuthenticationActions.sendPasswordResetCodeSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: ErrorStrings.GENERIC_RESET_PASSWORD_MESSAGE,
            }),
          );
          this.codeSent = true;
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AuthenticationActions.resetPasswordSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText:
                'Your password has been reset.  Please sign in with your username and new password.',
            }),
          );

          this.goBack();
        }),
      )
      .subscribe();

    this.signInError$
      .pipe(
        takeUntil(this.destroyed$),
        filter((error) => !!error),
        tap(({ error }) => {
          if (error.name === CognitoAuthErrorTypes.PASSWORD_RESET_REQUIRED) {
            this.bypassEmailAddress = true;
          }
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.sendPasswordResetCodeForm = this.formBuilder.group({
      email: ['', Validators.required],
    });

    this.resetPasswordForm = this.formBuilder.group({
      resetCode: ['', Validators.required],
      newPassword: [
        '',
        { validators: [Validators.required, passwordValidator()] },
      ],
      verifyPassword: [
        '',
        { validators: [Validators.required, passwordValidator()] },
      ],
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public goBack(): void {
    this.router.navigate([environment.appRoutes.signIn]);
  }

  public passwordIsEntered(): boolean {
    return this.resetPasswordForm.get('newPassword').value?.length > 0;
  }

  public passwordIsValid(): boolean {
    const passwordCriteria = checkPasswordCriteria(
      this.resetPasswordForm.get('newPassword').value,
    );

    return Object.keys(passwordCriteria).every(
      (check) => passwordCriteria[check] === true,
    );
  }

  public passwordsMatch(): boolean {
    const form = this.resetPasswordForm.value;
    return (
      form?.newPassword.length > 0 && form?.newPassword === form?.verifyPassword
    );
  }

  public resendCode(): void {
    this.sendCode(this.emailAddress);
  }

  public submitResetPasswordForm(): void {
    if (this.resetPasswordForm.invalid) {
      return;
    }
    const payload = this.resetPasswordForm.value;

    this.store$.dispatch(NotificationActions.reset());
    this.store$.dispatch(AuthenticationActions.resetPassword({ payload }));
  }

  public submitSendPasswordResetCodeForm(): void {
    if (this.sendPasswordResetCodeForm.invalid) {
      return;
    }
    const payload = this.sendPasswordResetCodeForm
      .get('email')
      .value.toLowerCase()
      .trim();

    this.emailAddress = payload;

    this.sendCode(payload);
  }

  public sendCode(payload: string) {
    this.store$.dispatch(NotificationActions.reset());
    this.store$.dispatch(
      AuthenticationActions.sendPasswordResetCode({ payload }),
    );
  }
}
