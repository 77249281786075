import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PanelWidth, ViewportMode } from '@app/shared/enums';
import { UserGrantItem, UserObject } from '@core/models';
import { PanelService } from '@core/services';
import { SelectUserGrantDialogComponent } from '@shared/ui/dialogs';
import { environment as env } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-nav',
    templateUrl: 'nav.component.html',
    styleUrls: ['./nav.component.scss'],
    standalone: false
})
export class AppNavComponent implements OnChanges, OnDestroy, OnInit {
  @Input() mode = ViewportMode.Default;
  @Input() selectedGrant: UserGrantItem;
  @Input() title = '';
  @Input() user: UserObject;
  @Input() version = '';

  @Output() appMenuButtonClick: EventEmitter<any> = new EventEmitter();

  public avatarInitials = '';
  public avatarURLStyle: SafeStyle;
  private destroyed$ = new Subject<boolean>();
  constructor(
    private panelService: PanelService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {}

  public get isDesktop(): boolean {
    return this.mode >= ViewportMode.Desktop;
  }

  public get isTablet(): boolean {
    return this.mode === ViewportMode.Tablet;
  }

  ngOnChanges(): void {
    this.avatarInitials = !this.user?.info
      ? ''
      : `${this.user?.info?.nameFirst.charAt(
          0,
        )}${this.user?.info?.nameLast.charAt(0)}`;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.avatarURLStyle = this.sanitizer.bypassSecurityTrustStyle(
      'url(' + this.user.avatar + ')',
    );
  }

  public appMenuButtonClicked(e): void {
    this.appMenuButtonClick.emit(e);
  }

  public openUserGrantListPanel(): void {
    this.panelService
      .open<boolean>(
        SelectUserGrantDialogComponent,
        {},
        { maxWidth: PanelWidth.Large },
      )
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  public signOut(): void {
    this.router.navigate([env.appRoutes.signOut]);
  }

  public viewAccount(): void {
    this.router.navigate(['/account/profile']);
  }

  public goToMainElement(event): void {
    event.preventDefault();
    const main = document.getElementById('content');
    main.setAttribute('tabindex', '-1');
    main.focus();
  }
}
